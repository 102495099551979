<script setup lang="ts">
import { onUnmounted, ref, type Ref } from 'vue'
import { useGroupsForUser } from '@/stores/group'
import { fakeInputFocus, isDataLoadError } from '@/util'
import {
  CellGroup,
  Cell,
  Button,
  Icon,
  List,
  SwipeCell,
  FloatingBubble,
  NavBar,
  Tag,
  Space,
  Sticky,
  Empty,
  Loading,
  Image
} from 'vant'
import { emit } from '@/eventbus'
const [groups, dispose] = useGroupsForUser({ active: true })
onUnmounted(dispose)
const openAddGroupPopup = () => {
  emit('createGroup', {})
  fakeInputFocus()
}
const openAddTaskPopup = () => {
  emit('createTask', {})
  fakeInputFocus()
}
</script>

<template>
  <div class="view-wrapper">
    <sticky>
      <nav-bar title="タスクグループ" safe-area-inset-top teleport="#nav-menu">
        <template #right>
          <Icon name="add-o" @click="openAddGroupPopup" size="large" />
        </template>
      </nav-bar>
    </sticky>
    <main>
      <div style="min-height: 100vh">
        <list :loading="isDataLoadError(groups) && groups.type == 'Loading'" loading-text="読み込み中">
          <template v-if="!isDataLoadError(groups)">
            <template v-for="group in groups" :key="group.id">
              <cell :label="group.description" label-class="van-multi-ellipsis--l2" is-link
                :to="{ name: 'group', params: { id: group.id } }">
                <template #title>
                  <div style="display: flex; justify-content: space-between;">
                    <span style="flex-shrink: 0;">{{ group.name }}</span>
                    <space style="flex-shrink: 1; overflow: hidden;">
                      <tag style="flex-shrink: 0;" type="primary" v-if="group.allowAnonymousAccess">公開</tag>
                      <tag style="flex-shrink: 0;" v-else-if="group.accessibleUsers.size > 1">共有中</tag>
                      <template v-if="group.accessibleUsers.size > 1">
                        <Image v-for="item in group.accessibleUsers.entries() " :key="item[0]"
                          :src="item[1].photoURL || 'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg'" round fill
                          width="22px" height="22px" />
                      </template>
                    </space>
                  </div>
                </template>
              </cell>
            </template>
          </template>
        </list>
        <empty v-if="isDataLoadError(groups) && (groups.type == 'InternalError' || groups.type == 'NotLoggedIn')"
          image="error" :description="groups.messageForUser" />
      </div>
      <floating-bubble @click="openAddTaskPopup" axis="lock" :gap="34">+</floating-bubble>
    </main>
  </div>
</template>
