<script setup lang="ts">
import { onUnmounted } from 'vue'
import { useTasksForGroup } from '@/stores/task'
import TaskList from '@/components/TaskList.vue'

const props = defineProps<{
  gid: string
  completed: boolean
  allowAnonymousAccess: boolean
}>()
const [tasks, off] = await useTasksForGroup({ gid: props.gid, completed: props.completed, loginRequired: !props.allowAnonymousAccess })
onUnmounted(off)
</script>

<template>
  <div>
    <task-list :tasks="tasks" :show-empty="completed == false" />
  </div>
</template>
