<script setup lang="ts">
import { updateTask, type Task, changeParentAndUpdate } from '@/stores/task'
import TaskItem from '@/components/TaskItem.vue'
import { List, Empty, Loading, showConfirmDialog } from 'vant'
import { isDataLoadError, type DataLoadError, calcMidOrder } from '@/util';

const props = defineProps<{
  tasks?: Task[] | DataLoadError
  showEmpty?: boolean
}>()
const onTaskDrop = async (fromTask: Task, toTask: Task, position: 'top' | 'bottom') => {
  if (props.tasks == null || isDataLoadError(props.tasks)) {
    return
  }
  if (fromTask.id == toTask.id) {
    return // same task
  }
  if (fromTask.parentGroupId != toTask.parentGroupId) {
    if (!(await showConfirmDialog({
      title: 'タスクを移動',
      message: '別のグループに移動しますか？',
      confirmButtonText: '移動',
      cancelButtonText: 'キャンセル'
    }).then(() => true).catch(() => false))) {
      return
    }
  }
  const toTaskIndex = props.tasks.findIndex((task) => task.id == toTask.id)
  if (toTaskIndex < 0) {
    console.warn('toTask not found')
    return // something wrong
  }
  const anotherToTask = props.tasks[toTaskIndex + (position == 'top' ? -1 : 1)]
  const prevOrder = position == 'top' ? anotherToTask?.order : toTask.order
  const nextOrder = position == 'bottom' ? anotherToTask?.order : toTask.order
  const targetOrder = calcMidOrder(prevOrder, nextOrder)
  if (fromTask.parentGroupId == toTask.parentGroupId) {
    await updateTask(fromTask.parentGroupId, fromTask.id, { order: targetOrder })
  } else {
    await changeParentAndUpdate(fromTask.parentGroupId, toTask.parentGroupId, fromTask.id, { order: targetOrder })
  }
}
</script>
<template>
  <div>
    <list v-if="!isDataLoadError(props.tasks) && props.tasks !== undefined && props.tasks.length > 0">
      <transition-group name="task-list" tag="div">
        <task-item v-for="task in props.tasks" :key="task.id" :task="task" @task-drop="onTaskDrop" />
      </transition-group>
    </list>
    <template v-else-if="isDataLoadError(props.tasks)">
      <loading v-if="props.tasks.type == 'Loading'">Loading...</loading>
      <empty v-else image="error" :description="props.tasks.messageForUser" />
    </template>
    <empty v-else-if="showEmpty" description="タスクを追加しましょう" />
  </div>
</template>

<style scoped>
.task-list-enter-active,
.task-list-leave-active {
  transition: all 0.3s ease;
}

.task-list-enter-from,
.task-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.task-list-move {
  opacity: 0.8;
  transition: transform 0.5s ease;
}
</style>
