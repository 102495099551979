import { initializeApp, type FirebaseOptions } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  getAuth,
  signInWithCustomToken,
  indexedDBLocalPersistence,
  connectAuthEmulator,
  updateProfile
} from 'firebase/auth'
import {
  getDatabase,
  connectDatabaseEmulator,
  type Query,
  type Unsubscribe,
  onValue,
  Database
} from 'firebase/database'
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import { promiseWithResolvers, type DataLoadError } from './util'
import mitt from 'mitt'
import type { User } from 'firebase/auth'
import { ref as vueRef, type Ref } from 'vue'
const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyBce7ud_Jr-WKzp8jJ7Iorob6Nf_tlkTqc',
  authDomain:
    import.meta.env.MODE === 'development'
      ? 'todolist-d1968.firebaseapp.com'
      : 'todo.app.shumi.pro',
  projectId: 'todolist-d1968',
  storageBucket: 'todolist-d1968.appspot.com',
  messagingSenderId: '371666754082',
  appId: '1:371666754082:web:2d1ca2931b86edfe27c87b',
  measurementId: 'G-8CYGD9PGV1',
  databaseURL: 'https://todolist-d1968-default-rtdb.asia-southeast1.firebasedatabase.app'
}

async function internalInitialize() {
  const useEmulator = import.meta.env.MODE === 'development' && location.protocol === 'http:'
  const app = initializeApp(firebaseConfig)
  const analytics = getAnalytics(app)
  const database = getDatabase(app)
  const functions = getFunctions(app)
  const auth = getAuth()

  if (useEmulator) {
    console.warn('Using Firebase Emulator')
    connectAuthEmulator(auth, `http://${location.hostname}:9099`, { disableWarnings: true })
    connectDatabaseEmulator(database, location.hostname, 9000)
    connectFunctionsEmulator(functions, location.hostname, 5001)
  }

  await auth.setPersistence(indexedDBLocalPersistence)

  return { app, analytics, database, auth, functions }
}

const initializePromise = promiseWithResolvers<Awaited<ReturnType<typeof internalInitialize>>>()

export function initializeFirebase() {
  const promise = internalInitialize()
  if (import.meta.env.MODE === 'development') {
    setTimeout(() => promise.then(initializePromise.resolve, initializePromise.reject), 5000)
  } else {
    promise.then(initializePromise.resolve, initializePromise.reject)
  }
  return promise
}

/**
 * get initialized firebase app
 */
export default function getFirebase() {
  return initializePromise.promise
}

export async function signInToFirebase(customToken: string) {
  const { auth } = await getFirebase()
  const promise = signInWithCustomToken(auth, customToken)
  return promise.then((userCredential) => {
    // Signed in
    const user = userCredential.user
    console.log('singed-in to firebase', userCredential)
  })
}

export const authStateEmitter = mitt<{ user: User | null }>()
let watching = false
let haveNeverSignedIn = true
const isLoggedInRef = vueRef(false)
export async function watchAuthState() {
  if (watching) {
    return
  }
  const { auth } = await getFirebase()
  watching = true
  return auth.onAuthStateChanged((user) => {
    if (haveNeverSignedIn && user == null) {
      return // skip
    }
    if (user != null) {
      haveNeverSignedIn = false
    }
    authStateEmitter.emit('user', user)
    isLoggedInRef.value = user != null
  })
}

export function getIsLoggedInRef() {
  watchAuthState()
  return isLoggedInRef
}

export function readAsVueRef<T>({
  loginRequired,
  queryBuilder,
  transform = (obj) => obj,
  transformError,
  loadingMessage = 'Loading...',
  localCacheKey = null,
  localCacheForNull = null
}: {
  loginRequired?: boolean
  queryBuilder: (user: User | null, db: Database) => Query
  transform?: (rawObject: any) => T
  transformError?: (err: Error, dafaultValue: DataLoadError) => T | DataLoadError
  loadingMessage?: string
  localCacheKey?: string | null
  localCacheForNull?: any | null
}): [Ref<T | DataLoadError>, Unsubscribe] {
  const data: Ref<T | DataLoadError> = vueRef({
    type: 'Loading',
    messageForUser: loadingMessage
  })
  if (localCacheKey != null) {
    const cachedRawValue: T | null = loadFromLocalCache(localCacheKey)
    if (cachedRawValue != null) {
      console.log('Using local cache:' + localCacheKey)
      data.value = transform(cachedRawValue)
    } else {
      console.log('Cache not found for:' + localCacheKey)
    }
  }
  const unsubscribePromise = promiseWithResolvers<void>()
  const f = async () => {
    const firebaseInstance = await getFirebase()
    const db = firebaseInstance.database
    let unsubscribeQuery = () => {}
    const onAuthStatusChange = async (user: User | null) => {
      unsubscribeQuery()
      if (user == null && loginRequired) {
        data.value = {
          type: 'NotLoggedIn',
          messageForUser: 'You are not logged in'
        }
      } else {
        unsubscribeQuery = onValue(
          queryBuilder(user, db),
          (snapshot) => {
            const rawValue = snapshot.val()
            data.value = transform(rawValue)
            if (localCacheKey != null) {
              if (rawValue != null) {
                saveToLocalCache(localCacheKey, rawValue)
              } else if (localCacheForNull != null) {
                saveToLocalCache(localCacheKey, localCacheForNull)
              }
            }
          },
          (err: Error) => {
            const defaultValue: DataLoadError = {
              type: 'InternalError',
              messageForUser: 'Failed to load data',
              error: err
            }
            if (transformError == null) {
              data.value = defaultValue
            } else {
              data.value = transformError(err, defaultValue)
            }
            console.error('Query failed', { err })
          }
        )
      }
    }
    authStateEmitter.on('user', onAuthStatusChange)
    watchAuthState() // no await
    const currentUser = firebaseInstance.auth.currentUser
    if (!loginRequired || currentUser != null) {
      onAuthStatusChange(currentUser)
    }
    unsubscribePromise.promise.then(() => {
      authStateEmitter.off('user', onAuthStatusChange)
      unsubscribeQuery()
    })
  }
  f()
  return [data, unsubscribePromise.resolve]
}

function loadFromLocalCache<T>(key: string): any | null {
  const value = localStorage.getItem(key)
  if (value == null) {
    return null
  }
  return JSON.parse(value) as T
}

function saveToLocalCache(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value))
}

export async function updateProfileIfRequired({
  displayName,
  photoURL
}: {
  displayName?: string
  photoURL?: string
}) {
  const isNonEmpty = (str: string | null) => str && str.trim().length > 0
  const { auth } = await getFirebase()
  const user = auth.currentUser
  if (user == null) {
    throw new Error('Not logged in')
  }
  if (isNonEmpty(user.displayName) || isNonEmpty(user.photoURL)) {
    return
  }
  if (isNonEmpty(user.displayName)) {
    displayName = user.displayName ?? undefined
  }
  if (isNonEmpty(user.photoURL)) {
    photoURL = user.photoURL ?? undefined
  }
  await updateProfile(user, { displayName, photoURL })
}

//
// --- Firebase Cloud Functions Definitions ---
//

interface GetOrCreateUserByLINELoginRequest {
  idToken: string
}
interface GetOrCreateUserByLINELoginResponse {
  customToken: string
}

export async function getOrCreateUserByLINELogin(idToken: string) {
  const { functions } = await getFirebase()
  const getOrCreateUserByLINELogin = httpsCallable<
    GetOrCreateUserByLINELoginRequest,
    GetOrCreateUserByLINELoginResponse
  >(functions, 'getOrCreateUserByLINELogin')
  return (await getOrCreateUserByLINELogin({ idToken })).data
}

interface FindGroupInfoByInvitationLinkRequest {
  groupId: string
  invitationLink: string
}

interface FindGroupInfoByInvitationLinkResponse {
  name: string
  isAlreadyMember: boolean
}

export async function findGroupInfoByInvitationLink(gid: string, invitationLink: string) {
  const { functions } = await getFirebase()
  const f = httpsCallable<
    FindGroupInfoByInvitationLinkRequest,
    FindGroupInfoByInvitationLinkResponse
  >(functions, 'findGroupInfoByInvitationLink')
  return (await f({ groupId: gid, invitationLink })).data
}

interface JoinToGroupByInvitationLinkRequest {
  groupId: string
  invitationLink: string
  displayName: string
  photoURL: string
}

type JoinToGroupByInvitationLinkResponse = void

export async function joinToGroupByInvitationLink(req: JoinToGroupByInvitationLinkRequest) {
  const { functions } = await getFirebase()
  const f = httpsCallable<JoinToGroupByInvitationLinkRequest, JoinToGroupByInvitationLinkResponse>(
    functions,
    'joinToGroupByInvitationLink'
  )
  return (await f(req)).data
}

export async function unlinkFromLINE() {
  const { functions } = await getFirebase()
  const f = httpsCallable<void, void>(functions, 'unlinkFromLINE')
  return (await f()).data
}

export async function linkToLINE(idToken: string) {
  const { functions } = await getFirebase()
  const f = httpsCallable<{ idToken: string }, void>(functions, 'linkToLINE')
  return (await f({ idToken })).data
}

export async function deleteAccount() {
  const { functions } = await getFirebase()
  const f = httpsCallable<void, void>(functions, 'deleteAccount')
  return (await f()).data
}
