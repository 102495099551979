<script setup lang="ts">
import { ref, watch, type Ref } from 'vue'
import { useRoute } from 'vue-router'
import GroupTaskList from '@/components/GroupTaskList.vue'
import { FloatingBubble, showConfirmDialog, type ShareSheetOption, showNotify, showDialog } from 'vant'
import { CellGroup, Button, Cell, Empty, NavBar, Icon, Sticky, Space, Loading, Tag, Image, Row, Col, Checkbox, Popover, showToast, ShareSheet } from 'vant'
import liff from '@line/liff'
import { useGroup, createInvitationLink, removeInvitationLink } from '@/stores/group'
import { type Group } from '@/models/group'
import { emit, userInitializedPromise } from '@/eventbus'
import { ApplicationError, fakeInputFocus, isDataLoadError, type DataLoadError } from '@/util'
import { onUnmounted } from 'vue'
import { getIsLoggedInRef } from '@/firebase'
import { buildGroupInvitationMessage } from '@/assets/groupInvitationMessage'
import LINEShareIcon from '@/assets/line_share.png'
import { liffUrl } from '@/liff'
import { buildPublicUrlShareMessage } from '@/assets/publicUrlShareMessage'
import { computed } from 'vue'
import { buildTaskShareMessage } from '@/assets/buildTaskShareMessage'
import { getTasksForGroup } from '@/stores/task'

const route = useRoute()
const showCompletedTasks = ref(false)
const showShareSheet = ref(false)
const showPublicUrlShareSheet = ref(false)
const showTaskShareSheet = ref(false)
const isLoggedIn = getIsLoggedInRef()
const groupCache: Record<string, [Ref<Group | null | DataLoadError>, () => void]> = {}
const group = computed(() => {
  const groupId = route.params.id
  if (groupId == null || Array.isArray(groupId)) {
    return { type: 'InternalError', messageForUser: 'グループが見つかりません' } as DataLoadError
  }
  if (groupCache[groupId] == null) {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    groupCache[groupId] = useGroup({ id: groupId, loginRequired: false })
  }
  return groupCache[groupId][0].value
})

onUnmounted(() => {
  Object.values(groupCache).forEach((item) => {
    item[1]()
  })
})
const openAddTaskPopup = () => {
  if (group.value != null && !isDataLoadError(group.value)) {
    emit('createTask', { groupId: group.value.id })
    fakeInputFocus()
  }
}

const shareUrlOptions: ShareSheetOption[] = []

if (liff.isInClient() && liff.isLoggedIn() && liff.getContext()?.scope.includes('chat_message.write')) {
  shareUrlOptions.push({ name: '開いているチャット', icon: LINEShareIcon, description: '開いているチャットに送る' })
}
if (liff.isLoggedIn() && liff.isLoggedIn()) {
  shareUrlOptions.push({ name: 'LINE', icon: LINEShareIcon, description: 'チャットを選択して送る' })
} else {
  shareUrlOptions.push({ name: 'LINE', icon: LINEShareIcon, description: 'LINE で送る' })
}
shareUrlOptions.push({ name: 'リンクをコピー', icon: 'link' })

const shareTaskOptions: ShareSheetOption[] = (liff.isInClient() && liff.isLoggedIn() && liff.getContext()?.scope.includes('chat_message.write')) ? [
  { name: '開いているチャット', icon: LINEShareIcon, description: '開いているチャットに送る' },
  { name: 'LINE', icon: LINEShareIcon, description: 'チャットを選択して送る' }
] : []

let nextInvitationLink: string | null = null

const openShareSheet = async () => {
  const g = group.value
  if (g == null || isDataLoadError(g)) {
    throw new Error('Group is null or loading')
  }
  showShareSheet.value = true
  if (nextInvitationLink != null) {
    await removeInvitationLink(g.id, nextInvitationLink)
    nextInvitationLink = null
  }
  nextInvitationLink = await createInvitationLink(g.id)
}
const onCloseShareSheet = async () => {
  const g = group.value
  if (g == null || isDataLoadError(g)) {
    throw new Error('Group is null or loading')
  }
  if (nextInvitationLink != null) {
    await removeInvitationLink(g.id, nextInvitationLink)
    nextInvitationLink = null
  }
}

const shareTask = async (option: ShareSheetOption) => {
  const g = group.value
  if (g == null || isDataLoadError(g)) {
    throw new Error('Group is null or loading')
  }
  const link = `${liffUrl}/group/${g.id}`
  const tasks = await getTasksForGroup({ gid: g.id })
  if (tasks.length === 0) {
    showTaskShareSheet.value = false
    showDialog({
      title: 'タスクがありません',
      message: `タスクを追加してから転送してください`,
      confirmButtonText: '閉じる'
    })
    return
  }
  const message = buildTaskShareMessage(g, tasks, link)
  const sendByLineMessage = async () => {
    if (await showConfirmDialog({
      title: '確認',
      message: '現在開いている LINE チャットにタスク一覧を送信しますか？',
      confirmButtonText: '送信',
      cancelButtonText: 'キャンセル'
    }).then(() => true).catch(() => false)) {
      await liff.sendMessages(message).catch(async (e) => {
        console.log('Failed to send message by sendMessages', e)
        await showDialog({
          message: '送信に失敗しました\nチャットを選択して送信します',
          confirmButtonText: 'OK'
        })
        throw e
      })
    }
  }
  const sendByLineShareTargetPicker = async () => {
    await liff.shareTargetPicker(message).catch((e) => {
      console.log('Failed to send message to LINE by shareTargetPicker', { err: e })
      throw e
    })
  }
  await (async () => {
    if (option.name === '開いているチャット') {
      if (liff.isLoggedIn()) {
        await sendByLineMessage().catch(sendByLineShareTargetPicker)
        showToast({ type: 'success', message: '送信しました' })
      } else {
        throw new ApplicationError('送信できませんでした')
      }
    } else if (option.name === 'LINE') {
      if (liff.isLoggedIn()) {
        await sendByLineShareTargetPicker()
      } else {
        throw new ApplicationError('送信できませんでした')
      }
    } else {
      throw new Error('Unknown option: ' + option.name)
    }
  })().catch(async (e) => {
    console.error('Failed to share public link', { err: e })
    if (e instanceof ApplicationError) {
      showNotify({ type: 'danger', message: e.messageForUser })
    } else {
      showNotify({ type: 'danger', message: e.message })
    }
  }).finally(() => {
    showTaskShareSheet.value = false
  })
}

const copyPublicShareUrl = async (option: ShareSheetOption) => {
  const g = group.value
  if (g == null || isDataLoadError(g)) {
    showPublicUrlShareSheet.value = false
    throw new Error('Group is null or loading')
  }
  if (option.name === 'リンクをコピー') {
    const link = `${location.origin}/group/${g.id}`
    await navigator.clipboard.writeText(link).then(() => {
      showToast({ type: 'success', message: 'リンクをコピーしました' })
    }).catch((e) => {
      console.error('Failed to write to clipboard', { err: e })
      showDialog({
        title: 'クリップボードに書き込めませんでした',
        message: `${link}\n上のリンクを選択してコピーしてください`,
        confirmButtonText: '閉じる'
      })
    })
    showPublicUrlShareSheet.value = false
    return
  }
  const sendByLineMessage = async () => {
    if (await showConfirmDialog({
      title: '確認',
      message: '現在開いている LINE チャットにリンクを送信しますか？',
      confirmButtonText: '送信',
      cancelButtonText: 'キャンセル'
    }).then(() => true).catch(() => false)) {
      const liffUrl = await liff.permanentLink.createUrlBy(`${location.origin}/group/${g.id}`)
      await liff.sendMessages(buildPublicUrlShareMessage(g, liffUrl)).catch(async (e) => {
        console.log('Failed to send message by sendMessages', e)
        await showDialog({
          message: '送信に失敗しました\nチャットを選択して送信します',
          confirmButtonText: 'OK'
        })
        throw e
      })
    }
  }
  const sendByLineShareTargetPicker = async () => {
    const liffUrl = await liff.permanentLink.createUrlBy(`${location.origin}/group/${g.id}`)
    await liff.shareTargetPicker(buildPublicUrlShareMessage(g, liffUrl)).catch((e) => {
      console.log('Failed to send message to LINE by shareTargetPicker', { err: e })
      throw e
    })
  }
  const sendBySocialLink = () => {
    const url = `${liffUrl}/group/${g.id}`
    location.href = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(url)}`
  }
  await (async () => {
    if (option.name === '開いているチャット') {
      if (liff.isLoggedIn()) {
        await sendByLineMessage().catch(sendByLineShareTargetPicker).catch(sendBySocialLink)
        showToast({ type: 'success', message: '送信しました' })
      } else {
        throw new ApplicationError('送信できませんでした')
      }
    } else if (option.name === 'LINE') {
      if (liff.isLoggedIn()) {
        await sendByLineShareTargetPicker().catch(sendBySocialLink)
      } else {
        sendBySocialLink()
      }
    } else {
      throw new Error('Unknown option: ' + option.name)
    }
  })().catch(async (e) => {
    console.error('Failed to share public link', { err: e })
    if (e instanceof ApplicationError) {
      showNotify({ type: 'danger', message: e.messageForUser })
    } else {
      showNotify({ type: 'danger', message: e.message })
    }
  }).finally(() => {
    showPublicUrlShareSheet.value = false
  })
}

const createAndCopyInvitationLink = async (option: ShareSheetOption) => {
  const g = group.value
  if (g == null || isDataLoadError(g)) {
    showShareSheet.value = false
    throw new Error('Group is null or loading')
  }
  if (option.name === 'リンクをコピー') {
    // clipboard API need to be executed in @click context
    const link = `${location.origin}/group/${g.id}/inv/${nextInvitationLink}`
    await navigator.clipboard.writeText(link).then(() => {
      nextInvitationLink = null // used
      showToast({ type: 'success', message: 'リンクをコピーしました' })
    }).catch((e) => {
      console.error('Failed to write to clipboard', { err: e })
      showDialog({
        title: 'クリップボードに書き込めませんでした',
        message: `${link}\n上のリンクを選択してコピーしてください`,
        confirmButtonText: '閉じる'
      })
    })
    showShareSheet.value = false
    return
  }
  const sendByLineMessage = async () => {
    if (await showConfirmDialog({
      title: '確認',
      message: '現在開いている LINE チャットに招待リンクを送信しますか？',
      confirmButtonText: '送信',
      cancelButtonText: 'キャンセル'
    }).then(() => true).catch(() => false)) {
      const liffUrl = await liff.permanentLink.createUrlBy(`${location.origin}/group/${g.id}/inv/${nextInvitationLink}`)
      await liff.sendMessages(buildGroupInvitationMessage(g, liffUrl)).catch(async (e) => {
        console.log('Failed to send message by sendMessages', e)
        await showDialog({
          message: '送信に失敗しました\nチャットを選択して送信します',
          confirmButtonText: 'OK'
        })
        throw e
      })
    }
  }
  const sendByLineShareTargetPicker = async () => {
    const liffUrl = await liff.permanentLink.createUrlBy(`${location.origin}/group/${g.id}/inv/${nextInvitationLink}`)
    await liff.shareTargetPicker(buildGroupInvitationMessage(g, liffUrl)).catch((e) => {
      console.log('Failed to send message to LINE by shareTargetPicker', { err: e })
      throw e
    })
  }
  const sendBySocialLink = () => {
    const url = `${liffUrl}/group/${g.id}/inv/${nextInvitationLink}`
    nextInvitationLink = null
    location.href = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(url)}`
  }
  await (async () => {
    if (option.name === '開いているチャット') {
      if (liff.isLoggedIn()) {
        await sendByLineMessage().catch(sendByLineShareTargetPicker).catch(sendBySocialLink)
        showToast({ type: 'success', message: '送信しました' })
        nextInvitationLink = null
      } else {
        throw new ApplicationError('送信できませんでした')
      }
    } else if (option.name === 'LINE') {
      if (liff.isLoggedIn()) {
        await sendByLineShareTargetPicker().catch(sendBySocialLink)
        nextInvitationLink = null
      } else {
        sendBySocialLink()
        nextInvitationLink = null
      }
    } else {
      throw new Error('Unknown option: ' + option.name)
    }
  })().catch(async (e) => {
    console.error('Failed to share invitation link', { err: e })
    if (e instanceof ApplicationError) {
      showNotify({ type: 'danger', message: e.messageForUser })
    } else {
      showNotify({ type: 'danger', message: e.message })
    }
    if (nextInvitationLink != null) {
      await removeInvitationLink(g.id, nextInvitationLink)
    }
  }).finally(() => {
    showShareSheet.value = false
  })
}
</script>
<template>
  <div class="view-wrapper">
    <template v-if="!isDataLoadError(group) && group != null">
      <sticky>
        <nav-bar :title="group.name + ' のタスク'" safe-area-inset-top>
          <template #left>
            <router-link :to="{ name: 'group-list' }">
              <Icon name="arrow-left" />Group
            </router-link>
          </template>
          <template #right>
            <Icon name="edit" size="large" @click="emit('updateGroup', { groupId: group.id })" />
          </template>
        </nav-bar>
      </sticky>
      <main>
        <div style="min-height: 100vh;">
          <cell-group>
            <cell>
              <div class="member-list-wrapper">
                <div class="member-list-no-shrink">
                  <tag v-if="group.allowAnonymousAccess" type="primary">
                    公開
                  </tag>
                  <tag v-else-if="group.accessibleUsers.size > 1">
                    共有中
                  </tag>
                  <tag v-else-if="group.invitationLinks.size > 0">
                    {{ group.invitationLinks.size }} 人招待中
                  </tag>
                  <tag v-else>
                    非公開
                  </tag>
                </div>
                <div class="member-list-icons">
                  <space>
                    <Image v-for="item in  group.accessibleUsers.entries() " :key="item[0]"
                      :src="item[1].photoURL || 'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg'" round fill
                      width="22px" height="22px" />
                  </space>
                </div>
                <div class="member-list-no-shrink">
                  <space>
                    <template v-if="group.allowAnonymousAccess">
                      <Button icon="link-o" size="small" @click="showPublicUrlShareSheet = true">公開URL</Button>
                    </template>
                    <template v-else>
                      <Button icon="link-o" size="small" @click="openShareSheet">招待</Button>
                    </template>
                  </space>
                </div>
              </div>
            </cell>
            <cell v-if="group.description.length > 0">
              <template #title>
                <pre style="white-space: pre-wrap;">{{ group.description }}</pre>
              </template>
            </cell>
          </cell-group>
          <cell-group inset>
            <template #title>
              <row justify="space-between">
                <Col>タスク</Col>
                <Col class="my-van-cell-group__title_toolbar">
                <space>
                  <popover placement="left-start">
                    <cell-group>
                      <cell>
                        <checkbox v-model="showCompletedTasks" @click.stop>
                          完了したタスクを表示
                        </checkbox>
                      </cell>
                      <cell v-if="shareTaskOptions.length > 0" clickable title="タスクをチャットに転送" icon="share-o"
                        @click="showTaskShareSheet = true" />
                    </cell-group>
                    <template #reference>
                      <Button plain round icon="ellipsis" size="small" />
                    </template>
                  </popover>
                </space>
                </Col>
              </row>
            </template>
            <group-task-list :gid="group.id" :completed="false" :allow-anonymous-access="group.allowAnonymousAccess" />
          </cell-group>
          <cell-group title="完了したタスク" v-if="showCompletedTasks" inset>
            <group-task-list :gid="group.id" :completed="true" :allow-anonymous-access="group.allowAnonymousAccess" />
          </cell-group>
        </div>
        <floating-bubble @click="openAddTaskPopup" axis="lock" :gap="34">+</floating-bubble>
        <share-sheet title="新しい招待リンクを作成" v-model:show="showShareSheet" cancel-text="Cancel" :options="shareUrlOptions"
          @select="createAndCopyInvitationLink" @close="onCloseShareSheet" />
        <share-sheet title="公開リンクを共有" v-model:show="showPublicUrlShareSheet" cancel-text="Cancel"
          :options="shareUrlOptions" @select="copyPublicShareUrl" />
        <share-sheet title="タスクをチャットに転送" v-model:show="showTaskShareSheet" cancel-text="Cancel"
          :options="shareTaskOptions" @select="shareTask" />
      </main>
    </template>
    <main v-else>
      <nav-bar title="Group Tasks">
        <template #left>
          <router-link :to="{ name: 'group-list' }">
            <Icon name="arrow-left" />Group
          </router-link>
        </template>
      </nav-bar>
      <empty v-if="group == null" image="error" description="グループが存在しないか、アクセス権がありません">
        <Button v-if="!isLoggedIn" :to="{ name: 'login', query: { redirect: route.fullPath } }">ログインする</Button>
      </empty>
      <cell-group v-else-if="isDataLoadError(group) && group.type == 'Loading'">
        <loading>Loading...</loading>
      </cell-group>
      <empty v-else image="error" :description="group.messageForUser" />
    </main>
  </div>
</template>
<style scoped>
.member-list-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.member-list-icons {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: scroll;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.member-list-icons::-webkit-scrollbar {
  display: none;
}

.member-list-icons>* {
  margin: auto 8px;
}

.member-list-no-shrink {
  flex-grow: 0;
  flex-shrink: 0;
}
</style>