import { type Ref } from 'vue'
import {
  ref,
  set,
  push,
  remove,
  get,
  query,
  equalTo,
  update,
  type Unsubscribe,
  orderByChild
} from 'firebase/database'
import { nanoid } from 'nanoid'
import getFirebase, { readAsVueRef } from '@/firebase'
import { removeAllTasksForGroup } from './task'
import {
  containsSameKeyValue,
  equalByJsonStringify,
  type DataLoadError,
  logErrorAndThrow
} from '@/util'
import {
  groupFromObject,
  groupKey,
  groupKeyForUser,
  groupKeyPrefix,
  groupKeyPrefixForUser,
  groupListFromObject,
  groupToObject,
  type NewGroup,
  type Group
} from '@/models/group'
import { updateGroupsToShowInTaskList } from './userData'

function calcGroupChangeSet(newGroup: Group, oldGroup: Group): Partial<Group> {
  if (newGroup.id != oldGroup.id) {
    throw new Error('id is immutable')
  }
  if (
    !containsSameKeyValue(newGroup.accessibleUsers, oldGroup.accessibleUsers, equalByJsonStringify)
  ) {
    const n = Object.fromEntries(newGroup.accessibleUsers.entries())
    const o = Object.fromEntries(oldGroup.accessibleUsers.entries())
    throw new Error(`accessibleUsers cannot be changed directly: new=${n}, old=${o}`)
  }
  if (newGroup.active != oldGroup.active) {
    throw new Error('active cannot be changed directly')
  }
  const changeSet: Partial<Group> = {}
  if (newGroup.name != oldGroup.name) {
    changeSet.name = newGroup.name
  }
  if (newGroup.description != oldGroup.description) {
    changeSet.description = newGroup.description
  }
  if (newGroup.allowAnonymousAccess != oldGroup.allowAnonymousAccess) {
    changeSet.allowAnonymousAccess = newGroup.allowAnonymousAccess
  }
  if (!containsSameKeyValue(newGroup.invitationLinks, oldGroup.invitationLinks)) {
    changeSet.invitationLinks = newGroup.invitationLinks
  }
  return changeSet
}

function changeSetToObject(changeSet: Partial<Group>) {
  const obj: any = { ...changeSet }
  if (changeSet.invitationLinks) {
    obj.invitationLinks = Object.fromEntries(changeSet.invitationLinks.entries())
  }
  if (changeSet.accessibleUsers) {
    obj.accessibleUsers = Object.fromEntries(changeSet.accessibleUsers.entries())
  }
  console.log('changeSetToObject', changeSet, obj)
  return obj
}

//
// -- method definitions --
//
let useActiveGroupsForUserCache: Ref<Group[] | DataLoadError> | null = null
export function useGroupsForUser({
  active
}: {
  active?: boolean
}): [Ref<Group[] | DataLoadError>, Unsubscribe] {
  if (active && useActiveGroupsForUserCache != null) {
    return [useActiveGroupsForUserCache, () => {}]
  }
  const res = readAsVueRef<Group[]>({
    loginRequired: true,
    queryBuilder: (user, db) =>
      query(
        ref(db, groupKeyPrefixForUser(user!.uid)), // user shouldn't be null since loginRequired is true
        orderByChild('active'),
        equalTo(active === true)
      ),
    transform: groupListFromObject,
    loadingMessage: 'Loading groups...'
  })
  if (active) {
    useActiveGroupsForUserCache = res[0]
    return [res[0], () => {}]
  }
  return res
}

export function useGroup({
  id,
  loginRequired = true
}: {
  id: string
  loginRequired?: boolean
}): [Ref<Group | null | DataLoadError>, Unsubscribe] {
  return readAsVueRef<Group | null>({
    loginRequired: loginRequired,
    queryBuilder: (user, db) => ref(db, groupKey(id)),
    transform: (v) => (v == null ? null : groupFromObject(v)),
    transformError: (err, defaultValue) => {
      if ((err as any).code == 'PERMISSION_DENIED') {
        console.warn(`getGroup: permission denied for ${id}`)
        repairUserDataGroupsToShowInTaskListByPermissionError(id)
        return null
      }
      return defaultValue
    },
    loadingMessage: 'Loading group...',
    localCacheKey: `group-${id}`
  })
}

export async function getGroup(id: string): Promise<Group | null> {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const result = await get(ref(db, groupKey(id))).catch((err) => {
    if (err.message == 'Permission denied') {
      console.warn(`getGroup: permission denied for ${id}`)
      return null
    }
    throw err
  })
  if (result != null && result.exists()) {
    return groupFromObject(result.val())
  }
  return null
}

export async function addGroup(newGroup: NewGroup) {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const currentUser = firebaseInstance.auth.currentUser
  if (currentUser == null) {
    throw new Error('current user is null')
  }
  const newObject = push(ref(db, groupKeyPrefix))
  if (newObject.key == null) {
    throw new Error('Failed to create new object key')
  }
  const group: Group = {
    ...newGroup,
    id: newObject.key,
    accessibleUsers: new Map(),
    invitationLinks: new Map()
  }
  group.accessibleUsers.set(currentUser.uid, {
    displayName: currentUser.displayName || '',
    photoURL: currentUser.photoURL || ''
  })
  const groupObject = groupToObject(group)
  const keyForUsers = Array.from(group.accessibleUsers.keys()).map((uid) =>
    groupKeyForUser(uid, group.id)
  )
  await Promise.all(
    keyForUsers.map((key) =>
      set(ref(db, key), groupObject).catch(
        logErrorAndThrow('Failed to add group for member', { key, groupObject })
      )
    )
  )
  await set(ref(db, groupKey(group.id)), groupObject).catch(
    logErrorAndThrow('Failed to add group for group index', {
      groupObject,
      key: groupKey(group.id)
    })
  )
  return group
}

export async function removeGroup(id: string) {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const group = await getGroup(id)
  if (group == null) {
    // Inconsistent state can happen if user to group index cleanup failed in the middle.
    // This block resolves the inconsistency.
    const currentUser = firebaseInstance.auth.currentUser
    if (currentUser != null) {
      console.warn(`Group ${id} is not found. Removing user to group index anyway.`)
      await remove(ref(db, groupKeyForUser(currentUser.uid, id)))
    }
    throw new Error(`Group ${id} is not found`)
  }
  // Remove tasks in the group first
  await removeAllTasksForGroup(id)
  // Then, remove group index prevent zombie status
  await remove(ref(db, groupKey(id))).catch(
    logErrorAndThrow('Failed to removeGroup for group index:' + groupKey(id))
  ) // NOTE: it removes tasks under the group
  // Then, remove user index
  const keyForUsers = Array.from(group.accessibleUsers.keys()).map((uid) =>
    groupKeyForUser(uid, group.id)
  )
  await Promise.all(
    keyForUsers.map((key) =>
      remove(ref(db, key)).catch(logErrorAndThrow('Failed to removeGroup for member:' + key))
    )
  )
}

// invite into group is implemented in could functions

export async function evictFromGroup(gid: string, uid: string) {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const group = await getGroup(gid)
  if (group == null) {
    throw new Error(`Group ${gid} is not found`)
  }
  if (group.accessibleUsers.size == 1) {
    throw new Error(`Group ${gid} has only one user. Cannot leave from the group.`)
  }
  // update other member's index
  await Promise.all(
    Array.from(group.accessibleUsers.keys())
      .filter((member) => uid != member)
      .map((member) => remove(ref(db, groupKeyForUser(member, gid) + '/accessibleUsers/' + uid)))
      .map((p) =>
        // ignore error here for inconsistent case handling
        p.catch((err) => {
          console.log("Failed to update other member's index. Ignoring the error", err)
        })
      )
  )
  await remove(ref(db, groupKeyForUser(uid, gid))).catch(
    logErrorAndThrow('Failed to evict user from group due to remove group for user failure')
  )
  await remove(ref(db, groupKey(gid) + '/accessibleUsers/' + uid)).catch(
    logErrorAndThrow(
      'Failed to evict user from group due to remove access permission in group index'
    )
  )
}

export async function leaveGroup(gid: string) {
  const { auth } = await getFirebase()
  const currentUser = auth.currentUser
  if (currentUser == null) {
    throw new Error('current user is null')
  }
  await evictFromGroup(gid, currentUser.uid)
}

export async function updateGroup(id: string, group: Partial<Group>) {
  const oldGroup = await getGroup(id)
  if (oldGroup == null) {
    throw new Error(`Group ${group.id} is not found`)
  }
  const newGroup = { ...oldGroup, ...group }
  // NOTE: accessibleUsers cannot be changed directly
  const changeSet = calcGroupChangeSet(newGroup, oldGroup)
  if (Object.keys(changeSet).length == 0) {
    console.log('UpdateGroup: no change')
    return
  }
  console.log('UpdateGroup: changeSet', changeSet)
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  await Promise.all(
    Array.from(newGroup.accessibleUsers.keys()).map((uid) =>
      update(ref(db, groupKeyForUser(uid, id)), changeSet).catch(
        logErrorAndThrow('Failed to update group for user:' + groupKeyForUser(uid, id))
      )
    )
  )
  await update(ref(db, groupKey(id)), changeSetToObject(changeSet)).catch(
    logErrorAndThrow('Failed to update group index:' + groupKey(id))
  )
}

export async function createInvitationLink(gid: string) {
  const group = await getGroup(gid)
  if (group == null) {
    throw new Error(`Group ${gid} is not found`)
  }
  const newInvitationLink = nanoid()
  group.invitationLinks.set(newInvitationLink, true)
  await updateGroup(gid, group)
  return newInvitationLink
}

export async function removeInvitationLink(gid: string, invitationLink: string) {
  const group = await getGroup(gid)
  if (group == null) {
    throw new Error(`Group ${gid} is not found`)
  }
  if (!group.invitationLinks.has(invitationLink)) {
    throw new Error(`Invitation link ${invitationLink} is not found`)
  }
  group.invitationLinks.delete(invitationLink)
  await updateGroup(gid, group)
}

async function repairUserDataGroupsToShowInTaskListByPermissionError(gid: string) {
  updateGroupsToShowInTaskList([{ gid, order: null }])
}
