//
// -- key definitions --
//
export const groupKeyPrefix = 'g'
export function groupKey(gid: string) {
  return groupKeyPrefix + '/' + gid
}

export function groupKeyPrefixForUser(uid: string) {
  return 'ug/' + uid
}

export function groupKeyForUser(uid: string, gid: string) {
  return groupKeyPrefixForUser(uid) + '/' + gid
}

//
// -- model definitions --
//

export interface GroupUserProfile {
  displayName: string
  photoURL: string
}

export interface NewGroup {
  name: string
  description: string
  active: boolean
  allowAnonymousAccess: boolean
}
export interface Group extends NewGroup {
  id: string
  accessibleUsers: Map<string, GroupUserProfile>
  invitationLinks: Map<string, boolean>
}

type GroupKey = keyof Group
const KEYS_MAP_TYPE = new Set(['accessibleUsers', 'invitationLinks'])

//
// -- model functions --
//

export function getDefaultGroup(): Group {
  return {
    id: '',
    name: '',
    description: '',
    active: true,
    allowAnonymousAccess: false,
    accessibleUsers: new Map(),
    invitationLinks: new Map()
  }
}

export function groupFromObject(obj: any) {
  const obj2: any = {}
  const defaultGroup = getDefaultGroup()
  if (obj == null) {
    return defaultGroup
  }
  Object.keys(defaultGroup).forEach((key) => {
    if (key in obj) {
      if (KEYS_MAP_TYPE.has(key)) {
        obj2[key] = new Map(Object.entries(obj[key]))
      } else {
        obj2[key] = obj[key]
      }
    }
  })
  if (obj2.id == null) {
    throw new Error('id is required')
  }
  // Migration
  Object.keys(obj2.accessibleUsers || {}).forEach((key) => {
    // accessibleUsers: Map<string, boolean> to Map<string, GroupUserProfile>
    if (typeof obj2[key] == 'boolean') {
      obj2[key] = { displayName: '', photoURL: '' }
    }
  })
  return { ...defaultGroup, ...obj2 }
}

export function groupToObject(group: Group) {
  const obj: any = {}
  const keys = Object.keys(group) as GroupKey[]
  keys.forEach((key: GroupKey) => {
    if (KEYS_MAP_TYPE.has(key)) {
      obj[key] = Object.fromEntries((group[key] as Map<string, any>).entries())
    } else {
      obj[key] = group[key]
    }
  })
  return obj
}

export function groupListFromObject(obj: any) {
  if (obj == null) {
    return []
  }
  return Object.values(obj).map(groupFromObject)
}
