<script setup lang="ts">
import type { Task } from '@/stores/task'
import { isTask, removeTask, updateTask } from '@/stores/task'
import { List, Cell, SwipeCell, Checkbox, Button, showNotify, Loading } from 'vant'
import { computed, ref } from 'vue'
import { emit } from '@/eventbus'
import { fakeInputFocus } from '@/util'
const props = defineProps<{
  task: Task
}>()
const emits = defineEmits<{
  taskDrop: [fromTask: Task, toTask: Task, position: 'top' | 'bottom'],
}>()

const description = computed(() => {
  const lines = props.task.contents.split('\n')
  return lines[0] + (lines.length > 1 ? '...' : '')
})
const updatingCompleted = ref(false)
function updateCompletedWithNotify() {
  const completed = !props.task.completed
  updatingCompleted.value = true

  updateTask(props.task.parentGroupId, props.task.id, { completed: completed })
    .catch((e) => {
      showNotify({ type: 'warning', message: e.message })
      console.error('Failed to update task', { err: e })
    }).finally(() => {
      updatingCompleted.value = false
    })
}
function removeTaskWithNotify() {
  updatingCompleted.value = true
  removeTask(props.task.parentGroupId, props.task.id)
    .catch((e) => {
      showNotify({ type: 'warning', message: e.message })
    }).finally(() => {
      updatingCompleted.value = false
    })
}
function openTaskEditPopup() {
  emit('updateTask', { groupId: props.task.parentGroupId, taskId: props.task.id })
  fakeInputFocus()
}
type DragOverStatus = 'top' | 'bottom' | 'none'
const dragging = ref(false)
const dragHovering = ref<DragOverStatus>('none')
function onDragStart(e: DragEvent) {
  if (e.dataTransfer) {
    e.dataTransfer.setData('text/plain', JSON.stringify(props.task))
    e.dataTransfer.effectAllowed = "move";
  }
  dragging.value = true
}
function onDragEnd(e: DragEvent) {
  dragging.value = false
  dragHovering.value = 'none'
}
// const itemReference = ref<HTMLElement>(null)
function onDragOver(e: DragEvent) {
  e.preventDefault();
  if (e.dataTransfer) {
    e.dataTransfer.dropEffect = "move";
  }
  const targetDOM = e.target as HTMLElement
  const rect = targetDOM.getBoundingClientRect()
  const offsetY = e.clientY - rect.top
  const ratioY = offsetY / rect.height
  if (ratioY < 0.5) {
    dragHovering.value = 'top'
  } else {
    dragHovering.value = 'bottom'
  }
}
function onDragLeave(e: DragEvent) {
  dragHovering.value = 'none'
}
function onDrop(e: DragEvent) {
  const position = dragHovering.value == 'none' ? 'top' : dragHovering.value
  dragHovering.value = 'none'
  const rawData = e.dataTransfer?.getData('text/plain')
  if (rawData == null) {
    return
  }
  const rawObject = JSON.parse(rawData)
  if (isTask(rawObject)) {
    const fromTask = rawObject
    emits('taskDrop', fromTask, props.task, position)
  }
}

const dragHoverStyle = computed(() => {
  if (dragHovering.value == 'bottom') {
    return {
      'border-bottom': '1px solid #e0e0e0',
    }
  } else if (dragHovering.value == 'top') {
    return {
      'border-top': '1px solid #e0e0e0',
    }
  }
  return {}
})
</script>
<template>
  <swipe-cell>
    <cell draggable="true" @dragstart.capture="onDragStart" @dragend.capture="onDragEnd" @dragleave.capture="onDragLeave"
      @drop.capture.stop="onDrop" @dragover.capture="onDragOver" :title="props.task.title" :label="description"
      label-class="van-multi-ellipsis--l2" clickable @click="openTaskEditPopup" :style="dragHoverStyle"
      class="my-van-cell--draggable">
      <template #right-icon>
        <checkbox v-if="!updatingCompleted" icon-size="24px" checked-color="#4caf50" :checked="props.task.completed"
          @click.stop="updateCompletedWithNotify" style="margin: -10px; padding: 10px" />
        <loading v-else color="#4caf50" size="24px" />
      </template>
    </cell>
    <template #right>
      <Button @click="removeTaskWithNotify" square type="danger" text="Delete" />
    </template>
  </swipe-cell>
</template>

<style scoped></style>
