import type { Group } from '@/models/group'
import type { SendMessagesParams } from '@liff/send-messages'

export function buildPublicUrlShareMessage(group: Group, liffUrl: string): SendMessagesParams {
  return [
    {
      type: 'flex',
      altText: `ToDo リスト "${group.name}"\n${liffUrl}`,
      contents: {
        type: 'bubble',
        body: {
          type: 'box',
          layout: 'vertical',
          contents: [
            {
              type: 'text',
              text: 'チャットから開ける ToDo リスト',
              weight: 'bold',
              size: 'xs'
            },
            {
              type: 'text',
              text: `${group.name}`,
              weight: 'bold',
              size: 'xxl',
              wrap: true,
              margin: 'md'
            },
            {
              type: 'box',
              layout: 'vertical',
              margin: 'lg',
              spacing: 'sm',
              contents: [
                {
                  type: 'text',
                  text: 'LINE のチャットで簡単に使える共有タスク管理 Web アプリ',
                  wrap: true
                }
              ]
            }
          ]
        },
        footer: {
          type: 'box',
          layout: 'vertical',
          spacing: 'sm',
          contents: [
            {
              type: 'button',
              style: 'primary',
              height: 'sm',
              action: {
                type: 'uri',
                label: 'アプリを開く',
                uri: liffUrl
              }
            }
            // {
            //   type: 'text',
            //   text: 'todo.app.shumi.pro',
            //   size: 'xs',
            //   color: '#aaaaaa',
            //   align: 'end',
            //   offsetEnd: 'sm'
            // }
          ],
          flex: 0
        }
      }
    }
  ]
}
