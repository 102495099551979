import getFirebase, { readAsVueRef } from '@/firebase'
import { get, onValue, ref, set, update } from 'firebase/database'
import { ref as vueRef } from 'vue'
import { addGroup, getGroup } from './group'
import { registerTutorialTasks } from './task'

//
// --- type definitions ---
//
export interface UserData {
  id: string
  defaultGroupId: string
  groupToShowInTaskList: Map<string, string> // gid -> order priority string
}
function getDefaultUserData(id: string): UserData {
  return {
    id: id,
    defaultGroupId: '',
    groupToShowInTaskList: new Map()
  }
}
function userDataFromObject(obj: any): UserData {
  const obj2: any = {}
  const defaultUserData = getDefaultUserData('')
  if (obj == null) {
    return defaultUserData
  }
  Object.keys(defaultUserData).forEach((key) => {
    if (key in obj) {
      if (key == 'groupToShowInTaskList') {
        obj2.groupToShowInTaskList = new Map(Object.entries(obj.groupToShowInTaskList))
      } else {
        obj2[key] = obj[key]
      }
    }
  })
  return { ...defaultUserData, ...obj2 }
}

function userDataToObject(userData: Partial<UserData>) {
  const obj: any = { ...userData }
  if (userData.groupToShowInTaskList != null) {
    obj.groupToShowInTaskList = Object.fromEntries(userData.groupToShowInTaskList.entries())
  }
  return obj
}

// function calcUserDataChangeSet(newUserData: UserData, oldUserData: UserData): Partial<UserData> {
//   if (newUserData.id != oldUserData.id) {
//     throw new Error('id is immutable')
//   }
//   const changeSet: Partial<UserData> = {}
//   if (newUserData.defaultGroupId != oldUserData.defaultGroupId) {
//     changeSet.defaultGroupId = newUserData.defaultGroupId
//   }
//   if (!containsSameKeyValue(newUserData.groupToShowInTaskList, oldUserData.groupToShowInTaskList)) {
//     changeSet.groupToShowInTaskList = newUserData.groupToShowInTaskList
//   }
//   return changeSet
// }

//
// -- key definitions --
//

function userDataKey(uid: string) {
  return 'ud/' + uid
}

//
// -- method definitions --
//
export interface LINEProfile {
  id: string
  displayName: string
  photoURL: string
}

export function useLINEProfile() {
  return readAsVueRef<LINEProfile | null>({
    loginRequired: true,
    queryBuilder: (user, db) => ref(db, `u/${user!.uid}/private/read/link/line`)
  })
}

export function useUserData() {
  return readAsVueRef<UserData>({
    loginRequired: true,
    queryBuilder: (user, db) => ref(db, userDataKey(user!.uid)),
    transform: userDataFromObject,
    localCacheKey: 'userData'
  })
}

export async function getUserData(): Promise<UserData | null> {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const currentUser = firebaseInstance.auth.currentUser
  if (currentUser == null) {
    throw new Error('user is not logged in')
  }
  const result = await get(ref(db, userDataKey(currentUser.uid)))
  if (result.exists()) {
    return userDataFromObject(result.val())
  }
  return null
}

export async function getOrInitializeUserData() {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const currentUser = firebaseInstance.auth.currentUser
  if (currentUser == null) {
    throw new Error('user is not logged in')
  }
  let shouldRegisterTutorialTasks = false
  let userData = await getUserData()
  if (userData == null) {
    console.log('User data not found. Initializing user data')
    userData = getDefaultUserData(currentUser.uid)
    await set(ref(db, userDataKey(currentUser.uid)), userDataToObject(userData))
    shouldRegisterTutorialTasks = true
  }
  // Data migration if required
  const changeSet: Partial<UserData> = {}
  const checkGroupIsActive = async (groupId: string) => {
    const group = await getGroup(groupId).catch(() => null)
    return group != null && group.active
  }
  if (userData.defaultGroupId == '' || !(await checkGroupIsActive(userData.defaultGroupId))) {
    const defaultGroup = await addGroup({
      name: 'インボックス',
      description: 'グループを選ばずに追加したタスクはここに入ります',
      active: true,
      allowAnonymousAccess: false
    })
    userData.defaultGroupId = defaultGroup.id
    changeSet.defaultGroupId = defaultGroup.id
    userData.groupToShowInTaskList.set(defaultGroup.id, 'N')
    changeSet.groupToShowInTaskList = userData.groupToShowInTaskList
  }
  if (userData.groupToShowInTaskList.size == 0) {
    userData.groupToShowInTaskList.set(userData.defaultGroupId, 'N')
    changeSet.groupToShowInTaskList = userData.groupToShowInTaskList
  }
  if (shouldRegisterTutorialTasks) {
    const f = async () => {
      const tutorialGroup = await addGroup({
        name: 'チュートリアル',
        description: 'このサービスの使い方を説明するためのグループです',
        active: true,
        allowAnonymousAccess: false
      })
      await registerTutorialTasks(tutorialGroup.id)
      if (userData != null) {
        userData.groupToShowInTaskList.set(tutorialGroup.id, 'O')
        changeSet.groupToShowInTaskList = userData.groupToShowInTaskList
      }
    }
    await f().catch((err) => {
      console.error('Failed to register tutorial tasks', err)
    })
  }
  if (Object.keys(changeSet).length > 0) {
    console.log('Migrating user data. changeSet:', changeSet)
    await update(ref(db, userDataKey(currentUser.uid)), userDataToObject(changeSet))
  }
  return userData
}

// delete if string is null
export async function updateGroupsToShowInTaskList(
  targets: { gid: string; order: string | null }[]
) {
  const { auth, database } = await getFirebase()
  const currentUser = auth.currentUser
  if (currentUser == null) {
    throw new Error('user is not logged in')
  }
  const updates: Record<string, string | null> = {}
  targets.forEach((target) => {
    const key = userDataKey(currentUser.uid) + '/groupToShowInTaskList/' + target.gid
    updates[key] = target.order
  })
  await update(ref(database), updates)
}
