console.time('load-application')
import './assets/main.css'
import 'vant/lib/index.css'
import * as Sentry from '@sentry/vue'

const sentryDns =
  import.meta.env.MODE === 'development'
    ? 'https://de04d6b1092116bb50f6637dfc2b321f@o113900.ingest.sentry.io/4506506145628160'
    : 'https://6a88e2affac58de3f0aec3ac12bb9073@o113900.ingest.sentry.io/4506506061152256'

import {
  initializeFirebase,
  signInToFirebase,
  getOrCreateUserByLINELogin,
  updateProfileIfRequired
} from './firebase'

import liff from '@line/liff'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { getOrInitializeUserData } from './stores/userData'
import { isInLINEInAppBrowser, liffEnsureLogin } from './liff'
import { appInitializedPromise, userInitializedPromise } from './eventbus'
async function initialize() {
  console.timeLog('load-application')
  console.log('Start initializing app')
  await (async () => {
    await liff.init({ liffId: import.meta.env.VITE_LINE_LIFF_ID })
    const app = createApp(App)

    Sentry.init({
      app,
      dsn: sentryDns,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['localhost', 'todo.app.shumi.pro', '192.168.1.103']
        }),
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: false
        })
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })

    app.use(createPinia())
    app.use(router)
    app.mount('#app')
  })()
    .then(() => appInitializedPromise.resolve())
    .catch((e) => {
      appInitializedPromise.reject(e)
      throw e
    })
    .finally(() => {
      console.timeLog('load-application')
      console.log('Finish initializing app')
    })
  if (!isInLINEInAppBrowser()) {
    // In case of LINE in-app browser, redirects to LIFF. So login is useless
    await loginOrCreateAccount()
  } else {
    console.timeEnd('load-application')
    console.log('Skip initializing user since in LINE in-app browser')
  }
}

async function loginOrCreateAccount() {
  console.timeLog('load-application')
  console.log('Start initializing user')

  await (async () => {
    const firebase = await initializeFirebase().catch((e) => {
      alert('Firebase initialization failed.' + e)
      throw e
    })
    const currentUser = firebase.auth.currentUser
    if (currentUser != null) {
      console.log('Firebase: already logged. current user:', currentUser)
      await getOrInitializeUserData()
      return
    }
    console.log('Firebase: current user is null.')
    localStorage.clear()
    if (liff.isInClient() || liff.isLoggedIn()) {
      console.log('Firebase: Trying login with LIFF user data')
      // -> LIFF
      // NOTE: LIFF should be already initialized
      const idToken = liffEnsureLogin()
      if (idToken !== undefined) {
        const result = await getOrCreateUserByLINELogin(idToken)
        await signInToFirebase(result.customToken)
        const decodedToken = await liff.getDecodedIDToken()
        if (decodedToken != null) {
          await updateProfileIfRequired({
            displayName: decodedToken.name || '',
            photoURL: decodedToken.picture || ''
          })
        }
        await getOrInitializeUserData()
      } else {
        // should be unreachable
        console.error('LIFF: idToken is undefined')
      }
      // console.log('lang', liff.getLanguage())
      // console.log('version', liff.getVersion())
      // console.log('isInClient', liff.isInClient())
      // console.log('isLoggedIn', liff.isLoggedIn())
      // console.log('getOS', liff.getOS())
      // console.log('getLineVersion', liff.getLineVersion())
    } else {
      console.log('Firebase: No auto-login methods')
    }
  })()
    .then(() => userInitializedPromise.resolve())
    .catch((e) => {
      userInitializedPromise.reject(e)
      throw e
    })
    .finally(() => {
      console.timeEnd('load-application')
      console.log('Finish initializing user')
    })
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initialize)
} else {
  initialize()
}
