import liff from '@line/liff'

export function liffEnsureLogin() {
  if (!liff.isLoggedIn()) {
    console.log('LIFF: not logged-in -> going to login with LINE')
    liff.login({ redirectUri: location.href })
    return // wait for login callback
  }
  console.log('LIFF: logged-in')
  const idToken = liff.getIDToken()
  const isIdTokenExpired = (liff.getDecodedIDToken()?.exp || 0) < Date.now() / 1000 + 60
  if (idToken == null || isIdTokenExpired) {
    console.log('LIFF: LINE idToken is null or expired -> going to login again')
    liff.logout()
    liff.login({ redirectUri: location.href })
    return // wait for login callback
  }
  return idToken
}

export function isInLINEInAppBrowser() {
  return navigator.userAgent.includes('Line') && !liff.isInClient()
}
export const liffUrl = 'https://liff.line.me/' + import.meta.env.VITE_LINE_LIFF_ID
export function redirectToLiff(path: string, query?: string) {
  window.location.replace(liffUrl + path + (query ? '?' + query : ''))
}
