<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router'
import { Tabbar, TabbarItem, FloatingBubble, Sticky, Overlay, Loading } from 'vant'
import { ref } from 'vue'
import { getIsLoggedInRef } from '@/firebase'
import { useRoute } from 'vue-router'
import { defineAsyncComponent } from 'vue'
const showLoading = ref(true)
const isLoggedIn = getIsLoggedInRef()
const route = useRoute()
const GroupAddPopup = defineAsyncComponent({
  loader: () => import('./components/GroupAddPopup.vue'),
})
const GroupEditPopup = defineAsyncComponent({
  loader: () => import('./components/GroupEditPopup.vue'),
})
const TaskAddPopup = defineAsyncComponent({
  loader: () => import('./components/TaskAddPopup.vue'),
})
const TaskEditPopup = defineAsyncComponent({
  loader: () => import('./components/TaskEditPopup.vue'),
})
</script>

<template>
  <RouterView v-slot="{ Component }">
    <KeepAlive include="GroupListView,TaskListView,GroupView">
      <Suspense timeout="100">
        <component :is="Component" />
        <template #fallback>
          <overlay v-model:show="showLoading" z-index="4">
            <div class="loading-wrapper">
              <div class="loading-block">
                <loading size="48px" vertical>少し待ってね...</loading>
              </div>
            </div>
          </overlay>
        </template>
      </Suspense>
    </KeepAlive>
  </RouterView>

  <tabbar route safe-area-inset-bottom>
    <tabbar-item v-if="isLoggedIn" to="/setting" icon="setting-o">設定</tabbar-item>
    <tabbar-item v-else :to="{ name: 'login', query: { redirect: route.fullPath } }"
      icon="user-circle-o">ログイン</tabbar-item>
    <tabbar-item to="/group" icon="send-gift-o">グループ</tabbar-item>
    <tabbar-item to="/task" icon="list-switch">タスク</tabbar-item>
    <tabbar-item> </tabbar-item>
  </tabbar>
  <div>
    <task-add-popup />
    <group-add-popup />
    <group-edit-popup />
    <task-edit-popup />
  </div>
</template>

<style scoped>
.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-block {
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
