import type { RouteParams } from 'vue-router'

export function containsSameKeyValue<Key, Value>(
  map1: Map<Key, Value>,
  map2: Map<Key, Value>,
  valueComparator: (l: Value, r: Value) => boolean = (a, b) => a === b
): boolean {
  if (map1.size !== map2.size) return false
  for (const [key, val] of map1) {
    if (!map2.has(key) || !valueComparator(val, map2.get(key)!)) return false
  }
  return true
}

export function containsSameItems(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) return false
  const arr1Set = new Set(arr1)
  for (const item of arr2) {
    if (!arr1Set.has(item)) return false
  }
  return true
}

export function equalByJsonStringify(a: any, b: any): boolean {
  return JSON.stringify(a) === JSON.stringify(b)
}

export function promiseWithResolvers<T>() {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers
  let resolve!: (value: T | PromiseLike<T>) => void
  let reject!: (reason?: any) => void
  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  })
  return { promise, resolve, reject }
}

export class ApplicationError {
  messageForUser: string
  error?: Error
  constructor(messageForUser: string, error?: Error) {
    this.messageForUser = messageForUser
    this.error = error
  }
}

export interface DataLoadError {
  type: 'Loading' | 'NotLoggedIn' | 'InternalError'
  messageForUser: string
  error?: Error
}
export function isDataLoadError(obj: any): obj is DataLoadError {
  return (
    obj != null &&
    typeof obj.type == 'string' &&
    typeof obj.messageForUser == 'string' &&
    (obj.error == null || obj.error instanceof Error)
  )
}

export function fakeInputFocus() {
  const fakeInput = document.createElement('input')
  fakeInput.setAttribute('type', 'text')
  fakeInput.style.position = 'absolute'
  fakeInput.style.opacity = '0'
  fakeInput.style.height = '0'
  fakeInput.style.fontSize = '16px' // disable auto zoom

  // you may need to append to another element depending on the browser's auto
  // zoom/scroll behavior
  document.body.append(fakeInput)

  // focus so that subsequent async focus will work
  fakeInput.focus()
  setTimeout(() => {
    fakeInput.remove()
  }, 2000)
}

export function logErrorAndThrow(message: string, args?: object) {
  return (err: Error) => {
    console.error(message, { err, ...(args || {}) }, err)
    throw err
    // if ((err as any).code == 'PERMISSION_DENIED' || err.message.startsWith('PERMISSION_DENIED')) {

    // }
  }
}

// given (null, C)  -> B
//       (null, B)  -> AN
//       (null, AN) -> AD
//       (null, AB) -> AAN
//       (null, AAB)-> AAAN
//
//       (Y, null)  -> YN
//       (YY, null) -> YYN
//
//       (AN, B) -> AS
//       (AY, B) -> AZ
//       (AZ, B) -> AZN
const MIN_CHAR_CODE = 'A'.charCodeAt(0)
const UPPER_CHAR_CODE = 'Z'.charCodeAt(0)
const CHAR_TO_ADD = 'N'
const APPEND_RATIO = 0.99
/**
 * random: randomly choose 'increment' or 'append' with APPEND_RATIO
 * increment: increment the last char
 * append: append CHAR_TO_ADD to the last char
 */
type SelectionStrategy = 'random' | 'increment' | 'append'

export function calcMidOrder(
  prevOrder?: string,
  nextOrder?: string,
  selectionStrategy: SelectionStrategy = 'random'
): string {
  // assume prevOrder < nextOrder
  if (prevOrder == undefined && nextOrder == undefined) {
    return CHAR_TO_ADD
  }
  if (prevOrder != undefined && nextOrder != undefined && prevOrder > nextOrder) {
    const tmp = prevOrder
    prevOrder = nextOrder
    nextOrder = tmp
  }
  // [prevCode + 0.5, nextCode) の範囲でランダムに返せばよい
  // prevCode より大きい値を返すと大きな文字に進んで、prevCode を返すと文字列長を増やして順序文字列を作る
  // タスクは基本下にどんどん追加されるので、できるだけ下の空間が圧迫されないような戦略をとる必要がある
  const decideMidChar = (prevCode: number, nextCode: number): number => {
    if (prevCode + 1 >= nextCode) {
      return prevCode + 0.5
    }
    if (prevOrder == null) {
      return nextCode - 1 // add to top
    }
    if (nextOrder == null) {
      if (
        selectionStrategy == 'append' ||
        (selectionStrategy == 'random' && Math.random() < APPEND_RATIO)
      ) {
        return prevCode + 0.5
      } else {
        return prevCode + 1
      }
    }
    return (prevCode + nextCode) / 2 // move to middle
  }
  const prevIndexAt = (i: number) => (prevOrder ? (i < prevOrder.length ? prevOrder[i] : 'A') : 'A')
  const nextIndexAt = (i: number) => (nextOrder ? (i < nextOrder.length ? nextOrder[i] : 'Z') : 'Z')
  const getPrevSlice = (i: number): string | undefined => {
    if (prevOrder == null) return undefined
    if (prevOrder.length > i) {
      return prevOrder.slice(0, i)
    } else {
      return prevOrder + 'A'.repeat(i - prevOrder.length)
    }
  }
  for (let i = 0; ; i++) {
    if (prevIndexAt(i) != nextIndexAt(i)) {
      const base = getPrevSlice(i) ?? nextOrder!.slice(0, i) // it can be prevOrder[:i] or prevOrder + 'AA...A
      const prevCode = prevIndexAt(i).charCodeAt(0)
      const nextCode = nextIndexAt(i).charCodeAt(0)
      const midCode = Math.floor(decideMidChar(prevCode, nextCode)) // choose smaller one
      if (midCode != prevCode) {
        // then, midCode shouldn't be nextCode
        return base + String.fromCharCode(midCode)
      }
      if (prevOrder == null) {
        // AA...
        return base + String.fromCharCode(midCode) + CHAR_TO_ADD
      } else {
        let result = base + String.fromCharCode(midCode)
        for (let j = i + 1; j < Math.max(prevOrder.length + 1, i + 2); j++) {
          // +2 is for i == prevOrder.length
          const prevCode2 = prevIndexAt(j).charCodeAt(0)
          if (prevCode2 != UPPER_CHAR_CODE) {
            // It should be always true at j == prevOrder.length
            const nextMidChar = Math.ceil(decideMidChar(prevCode2, UPPER_CHAR_CODE))
            if (nextMidChar == prevCode2) {
              throw new Error(
                `unreachable: ${prevCode2} ${nextMidChar} ${MIN_CHAR_CODE} ${UPPER_CHAR_CODE}`
              )
            }
            return result + String.fromCharCode(nextMidChar)
          } else {
            result = result + 'Z'
          }
        }
      }
      console.error({ prevOrder, nextOrder })
      throw new Error('unreachable')
    }
  }
}

export function getFirstRouteParamOrThrow(param: RouteParams, field: string): string {
  const paramValue = param[field]
  let result: string | null = null
  if (Array.isArray(paramValue)) {
    result = paramValue[0] ?? null
  } else {
    result = paramValue
  }
  if (!result) {
    throw new Error(`route param ${field} is not found`)
  }
  return result
}
