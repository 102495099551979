<script setup lang="ts">
import { userInitializedPromise } from '@/eventbus';
import getFirebase from '@/firebase';
import liff from '@line/liff';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import "firebaseui-ja/dist/firebaseui.css";
import { Button, Overlay } from 'vant';
import { onUpdated } from 'vue';
import { onBeforeUpdate } from 'vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
let ui: firebaseui.auth.AuthUI | null = null
const show = ref(true)
const router = useRouter()
const route = useRoute()
let unsubscribe = () => { }
// block loading component until user is initialized
await userInitializedPromise.promise
const message = route.query.message as string | undefined
const getRedirectUrl = () => {
    if (route.query.redirect) {
        return route.query.redirect as string
    } else {
        return '/'
    }
}
const redirectAfterLogin = () => {
    const url = getRedirectUrl()
    console.log("Redirect to " + url + " after login")
    router.push(url)
}

onMounted(async () => {
    console.log("Login view mounted. Initializing login UI")
    const { auth } = await getFirebase()
    if (auth.currentUser != null) {
        console.log("Login view: already loggedIn. Redirecting.")
        redirectAfterLogin()
    }
    unsubscribe = auth.onAuthStateChanged((user) => {
        if (user != null) {
            redirectAfterLogin()
        }
    })
    ui = firebaseui.auth.AuthUI.getInstance()
    if (ui == null) {
        console.log('initializing firebaseui')
        ui = new firebaseui.auth.AuthUI(auth)
    }
    console.log('starting firebaseui')
    ui.start('#firebaseui-auth-container', {
        signInSuccessUrl: getRedirectUrl(),
        signInOptions: [
            {
                provider: EmailAuthProvider.PROVIDER_ID,
            },
            {
                provider: GoogleAuthProvider.PROVIDER_ID,
            },
        ],
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                console.log("signInSuccessWithAuthResult", authResult, redirectUrl)
                return true
            },
            signInFailure: (error) => {
                console.log("signInFailure", error)
                return Promise.resolve()
            },
            uiShown: () => {
                console.log("firebaseui shown")
            },
        },
    })
})
onBeforeUnmount(async () => {
    console.log("Login view is going to be unmounted. Deleting login UI")
    if (ui != null) {
        ui.reset()
        ui = null
    }
    unsubscribe()
})

onBeforeUpdate(() => {
    console.log("Login view is going to be updated.")
})
onUpdated(() => {
    console.log("Login view is updated.")
})

async function lineLogin() {
    liff.login({ redirectUri: location.origin + getRedirectUrl() })
}

</script>

<template>
    <overlay v-model:show="show" teleport="#app" z-index="2">
        <div class="login-wrapper">
            <div class="login-block">
                <div>
                    <h2 style="text-align: center; padding: 24px">チャットから開ける ToDo リスト</h2>
                    <div style="text-align: center; padding: 5px 24px;">
                        {{ message }}<br>
                        ログインすると自動的にアカウントが作成されます
                    </div>
                </div>
                <div id="firebaseui-auth-container"></div>
                <div class="custom-login-container">
                    <div class="custom-login-row">
                        <button class="line-login-button" @click="lineLogin">
                            <div>
                                <div class="line-login-icon"></div>
                                <div class="line-login-border" />
                                <div class="line-login-text">LINEでログイン</div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </overlay>
</template>
<style scoped>
.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.login-block {
    background-color: white;
    border-radius: 3px;
    min-width: 80vw;
}

.custom-login-container {
    margin-top: 12px;
    margin-bottom: 36px;
}

.custom-login-row {
    margin: 0px auto;
    max-width: 360px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.line-login-button {
    padding: 0;
    outline: none;
    font: inherit;
    color: inherit;
    border: none;
    background: none;

    --line-login-button-x: 18px;
    --line-login-a: 4px;
    background-color: #06C755;
    /* margin: var(--line-login-a); */
    border-radius: var(--line-login-a);
    max-width: 220px;
    width: 100%;
    display: flex;
}

.line-login-button>div {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
}

.line-login-button>div:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.line-login-button>div:active {
    background-color: rgba(0, 0, 0, 0.3);
}

.line-login-button .line-login-icon {
    background-image: url('@/assets/line_white.png');
    background-size: contain;
    flex: 0 0 var(--line-login-button-x);
    margin: var(--line-login-a);
    height: var(--line-login-button-x);
}

.line-login-button .line-login-border {
    background-color: #000000;
    opacity: 0.08;
    flex: 0 0 1px;
    align-self: stretch;
}

.line-login-button .line-login-text {
    padding: calc(var(--line-login-button-x) / 2) var(--line-login-button-x);
    flex-grow: 1;
    flex-shrink: 0;
    color: #ffffff;
    text-align: center;
    font-size: 11px;
}
</style>
