<script setup lang="ts">
import { userInitializedPromise } from '@/eventbus'
import getFirebase, { findGroupInfoByInvitationLink, joinToGroupByInvitationLink } from '@/firebase'
import { getGroup } from '@/stores/group'
import { ApplicationError, getFirstRouteParamOrThrow, isDataLoadError } from '@/util'
import { group } from 'console'
import {
  Cell,
  CellGroup,
  Dialog,
  Field,
  Icon,
  Image,
  showDialog,
  Space,
  Overlay,
  Loading
} from 'vant'
import { computed } from 'vue'
import { ref, type Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const router = useRouter()
const route = useRoute()
const show = ref(false)
const showError = ref(false)
const errorMessage = ref('エラーが発生しました')

const groupId = getFirstRouteParamOrThrow(route.params, 'id')
const groupInvitationLink = getFirstRouteParamOrThrow(route.params, 'invitationLink')

const groupName: Ref<string | null> = ref(null)
const displayName: Ref<string> = ref('')
const photoURL: Ref<string> = ref('')

function goToGroup() {
  router.push({ name: 'group', params: { id: groupId } })
}

function goToHome() {
  router.push('/')
  return true
}

// If previously logged-in user is confirmed as member, go to group without waiting user initialization.
function goToGroupIfAlreadyMemberByLocalCache() {
  const cache = localStorage.getItem(
    'group-join-view--already-member-' + groupId + '-' + groupInvitationLink
  )
  if (cache != null) {
    goToGroup()
  }
}

async function load() {
  goToGroupIfAlreadyMemberByLocalCache()
  await userInitializedPromise.promise
  const { auth } = await getFirebase()
  if (auth.currentUser == null) {
    throw new Error('User is not logged in')
  }
  displayName.value = auth.currentUser.displayName || ''
  photoURL.value = auth.currentUser.photoURL || ''
  // try get group first to minimize cloud function usage
  const group = await getGroup(groupId).catch(() => null)
  if (group != null && !isDataLoadError(group) && group.accessibleUsers.has(auth.currentUser.uid)) {
    // go to group if already member
    localStorage.setItem(
      'group-join-view--already-member-' + groupId + '-' + groupInvitationLink,
      '1'
    )
    goToGroup()
    return
  }
  const groupInfo = await findGroupInfoByInvitationLink(groupId, groupInvitationLink).catch((e) => {
    if (e.code === 'functions/not-found') {
      throw new ApplicationError('グループ情報が取得できませんでした\n招待リンクが無効です', e)
    }
  })
  if (groupInfo?.isAlreadyMember) {
    goToGroup()
  }
  groupName.value = groupInfo?.name ?? null
}

const joinToGroup = async (action: string) => {
  if (action !== 'confirm') {
    router.push('/')
  }
  await joinToGroupByInvitationLink({
    groupId: groupId,
    invitationLink: groupInvitationLink,
    displayName: displayName.value,
    photoURL: photoURL.value
  }).catch((e: any) => {
    if (e.code == 'functions/already-exists') {
      goToGroup()
      return
    }
    console.error('Failed to joinToGroupByInvitationLink', { err: e })
    showDialog({
      title: 'エラー',
      message: 'グループ情報を取得できませんでした',
      confirmButtonText: 'OK'
    }).finally(goToHome)
    throw e
  })
  goToGroup()
  return true
}
await load()
  .then(() => {
    show.value = true
  })
  .catch((err: Error) => {
    if (err instanceof ApplicationError) {
      errorMessage.value = err.messageForUser
    } else {
      errorMessage.value = 'エラーが発生しました'
    }
    showError.value = true
  })
</script>
<template>
  <div class="view-wrapper">
    <Dialog
      v-model:show="show"
      title="グループに参加しますか？"
      :beforeClose="joinToGroup"
      confirm-button-text="参加"
      cancel-button-text="キャンセル"
      :show-cancel-button="true"
      transition="none"
    >
      <div class="group-join-view">
        <cell-group>
          <cell>グループに参加するとほかの参加者にあなたのユーザー名とアイコンが表示されます</cell>
          <cell title="グループ名">{{ groupName }}</cell>
          <field label="ユーザー名" v-model="displayName" input-align="right">
            <template #right-icon>
              <Icon name="edit" />
            </template>
          </field>
          <Cell title="アイコン" center>
            <space>
              <Image :src="photoURL || ''" width="22px" height="22px" />
              <Icon name="delete-o" size="large" @click="photoURL = ''" />
            </space>
          </Cell>
        </cell-group>
      </div>
    </Dialog>
    <Dialog
      v-model:show="showError"
      title="グループに参加: エラー"
      :message="errorMessage"
      confirm-button-text="閉じる"
      :beforeClose="goToHome"
    />
  </div>
</template>
<style scoped>
.group-join-view {
  padding: 10px;
}

.group-join-view ul {
  list-style: circle;
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-block {
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
