<script setup lang="ts">
import { onUnmounted, type Ref } from 'vue'

import TaskList from '@/components/TaskList.vue'
import { emit } from '@/eventbus'
import { useGroup } from '@/stores/group'
import { type Group } from '@/models/group'
import { useTasksForGroup, type Task } from '@/stores/task'
import { useUserData } from '@/stores/userData'
import { fakeInputFocus, isDataLoadError, type DataLoadError } from '@/util'
import type { Unsubscribe } from 'firebase/database'
import { Button, Cell, CellGroup, Col, Divider, Empty, FloatingBubble, Icon, Image, NavBar, Row, Skeleton, Sticky } from 'vant'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const openAddTaskPopup = (gid?: string) => {
  emit('createTask', { groupId: gid })
  fakeInputFocus()
}

const [userData, unsubscribeUserData] = useUserData()
const groupsToShow = computed(() => {
  if (!isDataLoadError(userData.value)) {
    return userData.value.groupToShowInTaskList
  }
  return userData.value
})
const groupsToTasksCache: Record<string, [Ref<Task[] | DataLoadError | null>, Unsubscribe]> = {}
const groupsCache: Record<string, [Ref<Group | DataLoadError | null>, Unsubscribe]> = {}
const groupsToTasks = computed(() => {
  if (isDataLoadError(groupsToShow.value)) {
    return groupsToShow.value
  }
  return Array.from(groupsToShow.value.entries())
    .sort((a, b) => {
      if (a[1] != b[1]) {
        return a[1] < b[1] ? -1 : 1 // compare by order string
      }
      return a[0] < b[0] ? -1 : 1 // compare by key order
    })
    .map(item => item[0]) // gid
    .map((gid) => {
      if (groupsToTasksCache[gid] == null) {
        const [tasks, unsubscribe] = useTasksForGroup({ gid, completed: false })
        groupsToTasksCache[gid] = [tasks, unsubscribe]
      }
      if (groupsCache[gid] == null) {
        const [group, unsubscribe] = useGroup({ id: gid })
        groupsCache[gid] = [group, unsubscribe]
      }
      return [groupsCache[gid][0], groupsToTasksCache[gid][0]] as [Ref<Group | DataLoadError | null>, Ref<Task[] | DataLoadError | null>]
    })
})
const isNoTasks = computed(() => {
  if (isDataLoadError(groupsToTasks.value) || groupsToTasks.value == null) return false
  return !groupsToTasks.value.some(item => {
    if (isDataLoadError(item[0].value) || item[0].value == null) return true
    if (isDataLoadError(item[1].value) || item[1].value == null) return true
    if (item[1].value.length > 0) return true
    return false
  })
})

onUnmounted(() => {
  unsubscribeUserData()
  Object.values(groupsToTasksCache).forEach(item => item[1]())
  Object.values(groupsCache).forEach(item => item[1]())
})
const router = useRouter()
</script>

<template>
  <div class="view-wrapper">
    <sticky>
      <nav-bar title="タスク" safe-area-inset-top />
    </sticky>
    <main>
      <div style="min-height: 100vh; padding-top: 10px;">
        <template v-if="!isDataLoadError(groupsToTasks)">
          <template v-for="item in groupsToTasks" :key="item[0]">
            <template v-if="item[0].value != null && !isDataLoadError(item[0].value)">
              <cell-group inset>
                <template #title>
                  <row justify="space-between" style="flex-wrap: nowrap;">
                    <Col style="flex-shrink: 0;"
                      @click="router.push({ name: 'group', params: { id: item[0].value.id } })">
                    {{ item[0].value.name }}
                    </Col>
                    <Col
                      style="overflow: hidden;flex-grow:1; flex-shrink: 1;display: flex; justify-content: flex-end; align-items: center; flex-wrap: nowrap; padding-left: 10px;"
                      class="my-van-cell-group__title_toolbar">
                    <template v-if="item[0].value.accessibleUsers.size > 1">
                      <div style="position: relative; flex-shrink: 1; flex-basis: 26px; height: 22px"
                        v-for="item2 in item[0].value.accessibleUsers.entries() " :key="item2[0]">

                        <Image style="position:absolute"
                          :src="item2[1].photoURL || 'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg'" round fill
                          width="22px" height="22px" />
                      </div>
                    </template>
                    <!-- <Button style="flex-shrink: 0;" plain round icon="plus" size="small"
                      @click="openAddTaskPopup(item[0].value.id)" /> -->
                    </Col>
                  </row>
                </template>
                <template v-if="item[1].value != null && !isDataLoadError(item[1].value)">
                  <task-list :tasks="item[1].value" />
                </template>
                <template v-else-if="item[1].value != null && item[1].value.type == 'Loading'">
                  <cell v-for="n in 3" :key="n">
                    <skeleton title />
                  </cell>
                </template>
              </cell-group>
            </template>
            <template v-else-if="item[0].value != null && item[0].value.type == 'Loading'">
              <cell-group>
                <template #title>
                  <skeleton title />
                </template>
                <cell v-for="n in 3" :key="n">
                  <skeleton title />
                </cell>
              </cell-group>
            </template>
          </template>
        </template>
        <template v-else-if="groupsToTasks.type == 'Loading'">
          <cell-group v-for="n in 3" :key="n">
            <template #title>
              <skeleton title />
            </template>
            <cell v-for="n in 3" :key="n">
              <skeleton title />
            </cell>
          </cell-group>
        </template>
        <Empty v-if="isNoTasks" description="タスクを追加しましょう" />
        <divider />
        <cell-group :border="false">
          <template #title><small>
              <Icon name="info-o" />グループの編集から「タスク一覧画面に追加」を設定するとこの画面に表示されます
            </small></template>
        </cell-group>
      </div>
      <floating-bubble @click="openAddTaskPopup()" axis="lock" :gap="34">+</floating-bubble>
    </main>
  </div>
</template>
