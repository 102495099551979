import mitt from 'mitt'
import { promiseWithResolvers } from './util'

type GlobalEvents = {
  createTask: { groupId?: string }
  updateTask: { groupId: string; taskId: string }
  createGroup: {}
  updateGroup: { groupId: string }
}

const emitter = mitt<GlobalEvents>()

export function on<T extends keyof GlobalEvents>(
  event: T,
  handler: (payload: GlobalEvents[T]) => any
) {
  emitter.on(event, handler)
  return () => emitter.off(event, handler)
}

export function emit(event: keyof GlobalEvents, payload: GlobalEvents[keyof GlobalEvents]) {
  console.log('emit', event, payload)
  emitter.emit(event, payload)
}

export const appInitializedPromise = promiseWithResolvers<void>()
export const userInitializedPromise = promiseWithResolvers<void>()
