import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized
} from 'vue-router'
import GroupListView from '../views/GroupListView.vue'
import GroupView from '../views/GroupView.vue'
import TaskListView from '../views/TaskListView.vue'
import GroupJoinView from '@/views/GroupJoinView.vue'
import LoginView from '@/views/LoginView.vue'
import getFirebase from '@/firebase'
import { isInLINEInAppBrowser, liffUrl } from '@/liff'
import liff from '@line/liff'
import { userInitializedPromise } from '@/eventbus'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/task',
      meta: { requiresLogin: true }
    },
    {
      path: '/group',
      name: 'group-list',
      component: GroupListView,
      meta: { requiresLogin: true }
    },
    {
      path: '/group/:id',
      name: 'group',
      component: GroupView,
      meta: { requiresLogin: false }
    },
    {
      path: '/group/:id/inv/:invitationLink',
      name: 'group-join',
      component: GroupJoinView,
      meta: { requiresLogin: true, loginMessage: 'グループに参加するためにログインしてください' }
    },
    {
      path: '/task',
      name: 'task-list',
      component: TaskListView,
      meta: { requiresLogin: true }
    },
    {
      path: '/setting',
      name: 'setting',
      component: () => import('@/views/SettingView.vue'),
      meta: { requiresLogin: true }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      beforeEnter: async (to, from, next) => {
        const { auth } = await getFirebase()
        if (auth.currentUser != null) {
          const url = (to.query.redirect as string) || '/'
          next(url)
        } else {
          next()
        }
      }
    },
    {
      path: '/liff',
      name: 'liff',
      component: () => import('@/views/OpenLIFFView.vue')
    }
  ]
})

let shouldReload = false

router.beforeEach((to, from, next) => {
  // Disabled until implementing version comparision
  // if (shouldReload) {
  //   const lastReload = localStorage.getItem('last-reload')
  //   if (lastReload != null && Date.now() - Number(lastReload) < 1000 * 60) {
  //     console.error('reload loop detected: client version=' + import.meta.env.VITE_APP_VERSION)
  //   } else {
  //     localStorage.setItem('last-reload', String(Date.now()))
  //     if (liff.isInClient()) {
  //       location.replace(liffUrl + to.fullPath)
  //     } else {
  //       location.reload()
  //     }
  //   }
  // }
  if (isInLINEInAppBrowser() && to.name != 'liff') {
    next({ name: 'liff', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})
router.afterEach((to, from) => {
  if (to.meta.requiresLogin) {
    const f = async () => {
      await userInitializedPromise.promise.catch()
      const { auth } = await getFirebase()
      if (auth.currentUser == null) {
        router.replace({
          name: 'login',
          query: { redirect: to.fullPath, message: to.meta.loginMessage as string | undefined }
        })
      }
    }
    f()
  }
})
import('@/assets/version.json').then(({ version }) => {
  const clientVersion = import.meta.env.VITE_APP_VERSION
  console.log('latest version:', version)
  console.log('client version:', clientVersion)
  shouldReload = clientVersion != version
})

export default router
