import { liffUrl } from '@/liff'
import type { Group } from '@/models/group'
import type { Task } from '@/stores/task'
import type { SendMessagesParams } from '@liff/send-messages'

export function buildTaskShareMessage(
  group: Group,
  tasks: Task[],
  link: string
): SendMessagesParams {
  return [
    {
      type: 'flex',
      altText: `Todo リスト "${group.name}" を開く\n${link}`,
      contents: {
        type: 'bubble',
        body: {
          type: 'box',
          layout: 'vertical',
          contents: [
            {
              type: 'text',
              text: 'チャットから開ける ToDo リスト',
              weight: 'bold',
              size: 'xs'
            },
            {
              type: 'text',
              text: group.name,
              weight: 'bold',
              size: 'xxl',
              margin: 'md'
            },
            {
              type: 'box',
              layout: 'vertical',
              margin: 'xl',
              spacing: 'sm',
              contents: tasks.map((task) => {
                return {
                  type: 'box',
                  layout: 'horizontal',
                  contents: [
                    {
                      type: 'box',
                      layout: 'vertical',
                      contents: [
                        {
                          type: 'filler'
                        },
                        {
                          type: 'box',
                          layout: 'vertical',
                          contents: [],
                          cornerRadius: '30px',
                          height: '12px',
                          width: '12px',
                          borderColor: '#555555',
                          borderWidth: '1px'
                        },
                        {
                          type: 'filler'
                        }
                      ],
                      flex: 0
                    },
                    {
                      type: 'text',
                      text: task.title,
                      gravity: 'center',
                      flex: 4,
                      size: 'sm',
                      color: '#555555'
                    }
                  ],
                  spacing: 'md'
                }
              })
            }
          ]
        },
        footer: {
          type: 'box',
          layout: 'vertical',
          spacing: 'sm',
          contents: [
            {
              type: 'button',
              style: 'primary',
              height: 'sm',
              action: {
                type: 'uri',
                label: 'アプリを開く',
                uri: link
              }
            }
            // {
            //   type: 'text',
            //   text: 'todo.app.shumi.pro',
            //   size: 'xs',
            //   color: '#aaaaaa',
            //   align: 'end',
            //   offsetEnd: 'sm'
            // }
          ],
          flex: 0
        }
      }
    }
  ]
}
