import { logErrorAndThrow, type DataLoadError, calcMidOrder } from '@/util'
import {
  endBefore,
  get,
  limitToLast,
  orderByChild,
  push,
  query,
  ref,
  remove,
  set,
  startAt,
  update,
  type Unsubscribe
} from 'firebase/database'
import { type Ref } from 'vue'
import getFirebase, { readAsVueRef } from '../firebase'
import { getGroup } from './group'

//
// --- type definitions ---
//
export interface NewTask {
  title: string
  contents: string
  completed: boolean
  parentGroupId: string
  childGroupId?: string
  /**
   * [A-Z]+ for completed = false
   * [a-z]+ for completed = true
   */
  order: string // a-z dictionary order
}
export interface Task extends NewTask {
  id: string
}

export function isTask(obj: any): obj is Task {
  return (
    obj != null && typeof obj == 'object' && 'id' in obj && 'title' in obj && 'parentGroupId' in obj
  )
}

function getDefaultTask(): Task {
  return {
    id: '',
    title: '',
    contents: '',
    completed: false,
    parentGroupId: '',
    childGroupId: undefined,
    order: 'N' // TODO:
  }
}

function taskFromObject(obj: any) {
  const obj2: any = {}
  const defaultTask = getDefaultTask()
  if (obj == null) {
    return defaultTask
  }
  Object.keys(defaultTask).forEach((key) => {
    if (key in obj) {
      obj2[key] = obj[key]
    }
  })
  if (obj2.id == null) {
    throw new Error('id is required')
  }
  // if (!(obj2.order instanceof String)) {
  //   obj2.order = obj2.completed ? 'n' : 'N' // TODO
  // }
  return { ...defaultTask, ...obj2 }
}

function taskListFromObject(obj: any): Task[] {
  if (obj == null) {
    return []
  }
  return Object.values(obj).map(taskFromObject)
}

function calcTaskChangeSet(newTask: Task, oldTask: Task): Partial<Task> {
  if (newTask.id != oldTask.id) {
    throw new Error('id is immutable')
  }
  if (newTask.parentGroupId != oldTask.parentGroupId) {
    throw new Error('parentGroupId cannot be changed directly')
  }
  if (newTask.childGroupId != oldTask.childGroupId) {
    throw new Error('childGroupId cannot be changed directly')
  }
  const changeSet: Partial<Task> = {}
  if (newTask.title != oldTask.title) {
    changeSet.title = newTask.title
  }
  if (newTask.contents != oldTask.contents) {
    changeSet.contents = newTask.contents
  }
  if (newTask.completed != oldTask.completed) {
    changeSet.completed = newTask.completed
    if (newTask.completed) {
      changeSet.order = newTask.order.toLowerCase()
    } else {
      changeSet.order = newTask.order.toUpperCase()
    }
  }
  if (newTask.order != oldTask.order) {
    changeSet.order = newTask.order
  }
  return changeSet
}

//
// -- key definitions --
//

function taskKeyPrefixForGroup(gid: string) {
  return 'gt/' + gid
}

function taskKeyForGroup(gid: string, tid: string) {
  return taskKeyPrefixForGroup(gid) + '/' + tid
}

export function useTasksForGroup({
  completed,
  gid,
  loginRequired = true
}: {
  completed?: boolean
  gid: string
  loginRequired?: boolean
}): [Ref<Task[] | DataLoadError>, Unsubscribe] {
  const rangeConstraint = completed ? startAt('a') : endBefore('a')
  return readAsVueRef<Task[]>({
    loginRequired: loginRequired,
    queryBuilder: (user, db) =>
      query(ref(db, taskKeyPrefixForGroup(gid)), orderByChild('order'), rangeConstraint),
    transform: (v) => taskListFromObject(v).sort((a, b) => (a.order < b.order ? -1 : 1)),
    loadingMessage: 'Loading tasks...',
    localCacheKey: `tasksForGroup-${gid}-a${completed ? '0' : '1'}`,
    localCacheForNull: []
  })
}

export async function getTasksForGroup({ gid }: { gid: string }): Promise<Task[]> {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const result = await get(
    query(
      ref(db, taskKeyPrefixForGroup(gid)),
      orderByChild('order'),
      endBefore('a'),
      limitToLast(10)
    )
  )
  return taskListFromObject(result.val())
}

export function useLastTaskForGroup({
  gid,
  loginRequired = true
}: {
  gid: string
  loginRequired?: boolean
}): [Ref<Task | null | DataLoadError>, Unsubscribe] {
  return readAsVueRef<Task | null>({
    loginRequired: loginRequired,
    queryBuilder: (user, db) =>
      query(
        ref(db, taskKeyPrefixForGroup(gid)),
        orderByChild('order'),
        endBefore('a'),
        limitToLast(1)
      ),
    transform: (obj) => {
      const list = taskListFromObject(obj)
      if (list.length == 0) {
        return null
      }
      return list[0]
    },
    loadingMessage: 'Loading last task...'
  })
}

export async function getTaskForGroup(gid: string, id: string): Promise<Task | null> {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const result = await get(ref(db, taskKeyForGroup(gid, id))).catch((err) => {
    console.error('Failed to get task for group', { gid, id, err })
    throw err
  })
  if (result.exists()) {
    return taskFromObject(result.val())
  }
  return null
}

export async function addTask(newTask: NewTask) {
  console.log('addTask', newTask)
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const group = await getGroup(newTask.parentGroupId)
  if (group == null) {
    throw new Error('parent group not found')
  }
  const newObject = await push(ref(db, taskKeyPrefixForGroup(group.id)))
  if (newObject.key == null) {
    throw new Error('Failed to create new object key')
  }
  const task: Task = {
    ...newTask,
    id: newObject.key
  }
  await set(ref(db, taskKeyForGroup(group.id, task.id)), task)
}

export async function removeTask(gid: string, id: string) {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  await remove(ref(db, taskKeyForGroup(gid, id)))
}

export async function removeAllTasksForGroup(gid: string) {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const group = await getGroup(gid)
  if (group == null) {
    throw new Error(`Group ${gid} is not found`)
  }
  await remove(ref(db, taskKeyPrefixForGroup(group.id))).catch(
    logErrorAndThrow('Failed to remove tasks from group index: ' + taskKeyPrefixForGroup(group.id))
  )
}

export async function updateTask(gid: string, id: string, task: Partial<Task>) {
  console.log('updateTask', gid, id, task)
  const oldTask = await getTaskForGroup(gid, id)
  if (oldTask == null) {
    throw new Error(`Task ${id} is not found`)
  }
  if (oldTask.parentGroupId != gid) {
    throw new Error(`Task ${id} is not in group ${gid} but in ${oldTask.parentGroupId}`)
  }
  const newTask = { ...oldTask, ...task }
  const changeSet = calcTaskChangeSet(newTask, oldTask)
  console.log('changeSet', changeSet)
  if (Object.keys(changeSet).length == 0) {
    return
  }
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database

  await update(ref(db, taskKeyForGroup(oldTask.parentGroupId, id)), changeSet).catch((err) => {
    console.error('Failed to update task for group', { gid, id, changeSet, err })
    throw err
  })
}

export async function changeParentAndUpdate(
  currentGid: string,
  targetGid: string,
  tid: string,
  task: Partial<Task>
) {
  console.log('changeParentAndUpdate', currentGid, targetGid, tid, task)
  if (currentGid == targetGid) {
    throw new Error('currentGid and targetGid must be different')
  }
  if (task.order == null) {
    throw new Error('order is required')
  }
  const oldTask = await getTaskForGroup(currentGid, tid)
  if (oldTask == null) {
    throw new Error(`Task ${tid} is not found`)
  }
  const newTask = { ...oldTask, ...task }
  const changeSet = calcTaskChangeSet(newTask, oldTask) // not used, but do for validation
  changeSet.parentGroupId = targetGid
  newTask.parentGroupId = targetGid
  if (newTask.childGroupId == null) {
    delete newTask['childGroupId'] // TODO: いい感じにする
  }
  console.log('changeSet', changeSet, 'newTask', newTask)

  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  await set(ref(db, taskKeyForGroup(targetGid, tid)), newTask)
  await remove(ref(db, taskKeyForGroup(currentGid, tid)))
}

export async function registerTutorialTasks(gid: string) {
  const firebaseInstance = await getFirebase()
  const db = firebaseInstance.database
  const lastTask = taskListFromObject(
    (
      await get(
        query(
          ref(db, taskKeyPrefixForGroup(gid)),
          orderByChild('order'),
          endBefore('a'),
          limitToLast(1)
        )
      )
    ).val()
  )?.at(0)
  const tutorialTasks: NewTask[] = [
    {
      title: 'このタスクを完了状態にしましょう',
      contents: '右のチェックボタンをタップすると完了できます',
      completed: false,
      parentGroupId: gid,
      order: ''
    },
    {
      title: 'タスクを追加してみましょう',
      contents: '右下の + ボタンからタスクを追加できます',
      completed: false,
      parentGroupId: gid,
      order: ''
    },
    {
      title: 'タスクを並べ替えてみましょう',
      contents: 'タスクをロングタップすると並べ替えられます',
      completed: false,
      parentGroupId: gid,
      order: ''
    },
    {
      title: 'タスクを削除してみましょう',
      contents: 'タスクを右にスワイプか、タスクをタップしたメニューから削除できます',
      completed: false,
      parentGroupId: gid,
      order: ''
    },
    {
      title: 'グループを作ってみましょう',
      contents: '「グループ」タブに移動して右上の + ボタンからグループを作成できます',
      completed: false,
      parentGroupId: gid,
      order: ''
    },
    {
      title: 'グループにほかの人を招待してみましょう',
      contents:
        '1. 「グループ」タブから作成したグループをタップ\n2. 移動したページで「招待リンク」をクリック\n3. LINE で送るか URL をコピーして友達に送ってください',
      completed: false,
      parentGroupId: gid,
      order: ''
    },
    {
      title: 'グループのタスクを LINE に転送してみましょう',
      contents: 'グループページの "..." ボタンをタップして「タスクをチャットに転送」を選択します',
      completed: false,
      parentGroupId: gid,
      order: ''
    },
    {
      title: 'LINE にアプリを開くボタンを送りましょう',
      contents: '「設定」タブ → 「LINE にアプリを開くボタンを送る」から送れます',
      completed: false,
      parentGroupId: gid,
      order: ''
    }
  ]
  let order = calcMidOrder(lastTask?.order || 'N', undefined)
  for (const task of tutorialTasks) {
    task.order = order
    order = calcMidOrder(order, undefined)
    await addTask(task)
  }
}
